<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-overline">Info</div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="local.name" :rules="$rules.requiredMax20" :counter="20" label="Car name" required />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="local.season" :rules="$rules.year" label="Season" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="text-overline">Settings</div>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="local.wheelbase" :rules="$rules.year" label="Wheelbase" suffix="mm" required />
        </v-col>
        <v-col cols="12">
          <div class="text-overline">Shim Factors</div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="local.shimFactors.rideHeight"
            :rules="$rules.number"
            label="Ride Height Shim Factor"
            required
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field v-model="local.shimFactors.weight" :rules="$rules.number" label="Weight Shim Factor" required />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="local.shimFactors.frontToe"
            :rules="$rules.number"
            label="Front Toe Shim Factor"
            required
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="local.shimFactors.rearToe"
            :rules="$rules.number"
            label="Rear Toe Shim Factor"
            required
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="local.shimFactors.frontCamber"
            :rules="$rules.number"
            label="Front Camber Shim Factor"
            required
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="local.shimFactors.rearCamber"
            :rules="$rules.number"
            label="Rear Camber Shim Factor"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="submit">
            <slot />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'
export default {
  name: 'CarInputDataForm',
  props: {
    car: {
      type: Object,
      default() {
        return {
          name: '',
          season: new Date().getFullYear(),
          wheelbase: '1530',
          shimFactors: {
            rideHeight: '0.827',
            weight: '0.106',
            toe: '1',
            camber: '1'
          }
        }
      }
    }
  },
  data: () => ({
    valid: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.car)
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        name: this.local.name,
        season: parseInt(this.local.season),
        wheelbase: parseFloat(this.local.wheelbase),
        shimFactors: {
          rideHeight: parseFloat(this.local.shimFactors.rideHeight),
          weight: parseFloat(this.local.shimFactors.weight),
          frontToe: parseFloat(this.local.shimFactors.frontToe),
          rearToe: parseFloat(this.local.shimFactors.rearToe),
          frontCamber: parseFloat(this.local.shimFactors.frontCamber),
          rearCamber: parseFloat(this.local.shimFactors.rearCamber)
        }
      }
      this.$emit('submit', data)
    }
  }
}
</script>
