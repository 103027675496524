<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="car in cars" :key="car.id">
        <v-skeleton-loader v-if="car.id === '-1'" type="card" height="266"></v-skeleton-loader>
        <car-info-card v-else :car="car" @edit="openEdit(car)" @delete="openDelete(car)"></car-info-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <v-card outlined @click="showNewCarSheet = true">
          <div class="card-plus-icon">
            <v-icon x-large>mdi-plus</v-icon>
          </div>
          <v-card-text>
            <v-row>
              <div class="text-subtitle-1 pa-1">Create new Car</div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-bottom-sheet v-model="showNewCarSheet" scrollable>
      <v-card>
        <v-card-title>Create a new Car</v-card-title>
        <v-card-text>
          <car-input-data-form @submit="createCar">Create</car-input-data-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-if="selectedCar" v-model="showEditCarSheet" scrollable>
      <v-card>
        <v-card-title>Update a Car</v-card-title>
        <v-card-text>
          <car-input-data-form :car="selectedCar" @submit="updateCar">Update</car-input-data-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <app-delete-dialog :show="showDeleteCarDialog" @delete="deleteCar" @close="showDeleteCarDialog = false">
      Delete a car will also delete all dependent setups (and more)! Are you sure your want delete this car?
    </app-delete-dialog>
  </v-container>
</template>

<script>
import AppDeleteDialog from '@components/app/DeleteDialog'
import CarInfoCard from '@components/cars/InfoCard'
import CarInputDataForm from '@components/cars/InputDataForm'
import { CarSettings, CreateCar, UpdateCar, DeleteCar } from '@graphql/Cars.gql'

// TODO: Offline -> Can't get Cars -> Show Online/Offline Status
// TODO: Offline -> https://www.apollographql.com/docs/react/data/error-handling/ -> Retry Links & Failed to connect
// TODO: Add Subscriptions
export default {
  name: 'CarsPage',
  components: { AppDeleteDialog, CarInfoCard, CarInputDataForm },
  apollo: {
    cars: {
      query: CarSettings
    }
  },
  data: () => ({
    cars: [],
    showNewCarSheet: false,
    showEditCarSheet: false,
    showDeleteCarDialog: false,
    selectedCar: undefined
  }),
  methods: {
    openEdit(car) {
      this.selectedCar = car
      this.showEditCarSheet = true
    },
    openDelete(car) {
      this.selectedCar = car
      this.showDeleteCarDialog = true
    },
    createCar(data) {
      const newCar = {
        id: '-1',
        ...data
      }
      this.cars.push(newCar)
      const variables = {
        data: {
          name: data.name,
          season: data.season,
          wheelbase: data.wheelbase,
          shimFactors: { create: { ...data.shimFactors } }
        }
      }
      this.$apollo
        .mutate({ mutation: CreateCar, variables })
        .then(({ data: { createCar } }) => {
          newCar.id = createCar.id
          this.showNewCarSheet = false
        })
        .catch((res) => {
          const carIdx = this.cars.findIndex((c) => c.id === '-1')
          this.cars.splice(carIdx, 1)
          this.$store.commit('openErrorDialog', res)
        })
    },
    updateCar(data) {
      const variables = {
        where: {
          id: this.selectedCar.id
        },
        data: {
          name: { set: data.name },
          season: { set: data.season },
          wheelbase: { set: data.wheelbase },
          shimFactors: {
            upsert: {
              update: {
                rideHeight: { set: data.shimFactors.rideHeight },
                weight: { set: data.shimFactors.weight },
                frontToe: { set: data.shimFactors.frontToe },
                rearToe: { set: data.shimFactors.rearToe },
                frontCamber: { set: data.shimFactors.frontCamber },
                rearCamber: { set: data.shimFactors.rearCamber }
              },
              create: {
                rideHeight: data.shimFactors.rideHeight,
                weight: data.shimFactors.weight,
                frontToe: data.shimFactors.frontToe,
                rearToe: data.shimFactors.rearToe,
                frontCamber: data.shimFactors.frontCamber,
                rearCamber: data.shimFactors.rearCamber
              }
            }
          }
        }
      }
      this.$apollo
        .mutate({ mutation: UpdateCar, variables })
        .then(({ data: { updateCar } }) => {
          const car = this.cars.find((car) => car.id == updateCar.id)
          Object.assign(car, updateCar)
          this.showEditCarSheet = false
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    },
    deleteCar() {
      const variables = {
        where: {
          id: this.selectedCar.id
        }
      }
      this.$apollo
        .mutate({ mutation: DeleteCar, variables })
        .then(({ data: { deleteCar } }) => {
          const carIdx = this.cars.findIndex((car) => car.id == deleteCar.id)
          this.cars.splice(carIdx, 1)
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
      this.showDeleteCarDialog = false
    }
  }
}
</script>

<style scoped>
.card-plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 222px;
}
</style>
