<template>
  <v-card height="100%">
    <v-img
      height="70"
      :src="
        $vuetify.theme.dark ? require('@assets/dallara-gp208_white.png') : require('@assets/dallara-gp208_black.png')
      "
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
      <v-card-title class="pa-1">{{ car.name }}</v-card-title>
    </v-img>
    <v-card-text>
      <v-row>
        <div class="text-subtitle-1 pa-1">{{ car.season }}</div>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click.stop="$emit('edit')">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop="$emit('delete')">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-overline">Wheelbase</div>
          <div class="text-body-01">{{ car.wheelbase }} mm</div>
          <div class="text-overline">Shim Factors</div>
          <div class="text-body-1">Ride Height: {{ car.shimFactors.rideHeight }}</div>
          <div class="text-body-1">Weight: {{ car.shimFactors.weight }}</div>
          <div class="text-body-1">Front Toe: {{ car.shimFactors.frontToe }}</div>
          <div class="text-body-1">Rear Toe: {{ car.shimFactors.rearToe }}</div>
          <div class="text-body-1">Front Camber: {{ car.shimFactors.frontCamber }}</div>
          <div class="text-body-1">Rear Camber: {{ car.shimFactors.rearCamber }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CarCard',
  props: { car: { type: Object, required: true } }
}
</script>
